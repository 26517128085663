<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Our Technical Area of Focus</h1>
        </div>

      </v-toolbar>

      <v-container>
        <v-card flat>
          <v-card-title>
            GLOPPICON has also specialist areas of focus (technical sectors) which are mentioned below:
          </v-card-title>
        </v-card>
        <v-divider/>

        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Rural development (Sustainable Livelihoods, Pastoralism and Food Security Rural Institutions & Change and Natural Resource Management). </v-card-title>
                <v-card-text>
                  GLOPPICON has considerable expertise and experience in agro-pastoral, pastoral and urban sustainable livelihoods and food security, community-based natural resource management (rangeland management and environmental protection), pastoralisms and rural transformation and change
                </v-card-text>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp;  Social Services (Health , WASH and Education)  </v-card-title>
                <v-card-text>
                  Social services are one of the technical sectors where GLOPPICON has got the extensive knowledge, skills and experiences. GLOPPICON consultants have the technical competence in health, WASH and Education. They have addressed key technical issues in these areas and have also provided relevant advisory services and researches in these areas.
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Conflict mitigation, transformation and peace building </v-card-title>
                <v-card-text>
                  GLOPPICON consultants have also extensive experiences in conflict prevention, mitigation, transformation and management at local, national regional levels. GLOPPICON has also developed an expertise in peace building interventions. Conflict Analysis and Conflict Sensitive Programming and approaches are also GLOPPICON’s Specialist areas.
                </v-card-text>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Resilience, DRR and Drought/Disaster management</v-card-title>
                <v-card-text>
                  GLOPPICON consultants are experienced in Disaster Risk Reduction (DRR) and enhancing resilience of communities. They have exceptional expertise in assessing vulnerability of the communities to disaster risks and to reduce those risks. The consultants have been involved in DRR policy level and practical implementation of DRR policies and plans. They have also provided durable advisory services on DRR and Resilience building. In addition, GLOPPICON consultants have been involved in drought/disaster preparedness and management at policy and practical levels. Community based early warning system was one of the tools or strategies employed by GLOPPICON in drought/disaster preparedness.

                </v-card-text>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Human rights and Good Governance </v-card-title>
                <v-card-text>
                  GLOPPICON consultants are highly experienced in human rights and good governance. They are experts of international human rights instruments and associated international law. They have managed human rights, child rights and women rights and minority rights projects. They carried out extensive researches on human rights situations in East and Horn of Africa sub-regions. they have also monitored human rights violations in the same region, assessed the capacity gaps of human rights NGOs and strengthened the capacity of Civil Society Organizations on human rights.

                  GLOPPICON consultants have also been experienced in governance systems in the region. They carried out governance analysis, identified core governance issues. They also managed governance programmes and projects in the same region. They assessed the supply and demand sides of governance and empowered communities and SCOs to demand their rights. They also contributed to the responsiveness of public institutions to the demands of the public. In addition, they have the expertise in increasing the efficiency and effectiveness of public institutions as well as strategic capacity development.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FocusAreaComponent",
  data: () => ({
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      {
        title: 'Strategic planning and policy formulations',
        description:'GLOPPICON consultants facilitate strategic planning and policy formulation exercises. They are experienced in both contextual and policy analysis and in supporting the partners in conducting SWOT, PESTLE, Stakeholder, gender analysis; and in identifying and evaluating strategic choices and their implications; and agreeing on organizational strategic direction in the context of turbulent and dynamic global, regional and national environment. We also support the partners in collating, analyzing, evaluating and reviewing appropriate data on specific policy issues before policy adoption.\n' +
            '\n' +
            'GLOPPICON has a pool of professional and experienced facilitators and trainers who facilitate seminars, workshops, conferences and professional training programmes.'
      },
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
  })
}
</script>

<style scoped>

</style>
