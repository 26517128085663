<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Aims & Objectives</h1>
        </div>

        <template v-slot:extension>
          <v-tabs
              v-model="tabs"
              centered
          >
            <v-tab
                v-for="(n,index) in services"
                :key="index"
            >
              {{ n.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-container>
        <v-card flat>
          <v-card-title>
            In order to achieve the organizational vision and mission, GLOPPI has the following goals and their aims and rights they contribute:
          </v-card-title>
        </v-card>
        <v-divider/>

        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Right One: The right to security. </v-card-title>
                <v-list>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 1:- The maximum possible number of target people we work with will be safe from death and brutal killing and suffering caused by violent conflict. </v-list-item>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 2-  The maximum possible number of target people we work with will have access to food, water, and other necessities in the context of emergencies and displacement. </v-list-item>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 3:- The maximum possible number of target people we work with will have strengthened capacity to cope with man-made and natural disasters and will reduce impact of disasters on lives and livelihoods.</v-list-item>
                </v-list>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Right Two: The right to sustainable livelihood and secured income. . </v-card-title>
                <v-list>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 1:- The maximum possible number of target people we work with will attain acceptable level of income and sustain livelihoods and will enjoy better living standards. </v-list-item>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 2-   The maximum possible number of target people we work with will have improved access to health, education and adequate and safe water.. </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Right Three: The right to participation and protection. </v-card-title>
                <v-list>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 1:- The maximum possible number of target people we work with will exercise and enjoy security, socio-cultural, economic and political rights and will fully participate in and influence decision making at different levels. </v-list-item>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 2-  The maximum possible number of target people we work with will have the strong capacity to advocate for their rights and will have their voices heard. </v-list-item>
                  <v-list-item><v-icon small color="error">mdi-check-circle</v-icon> Goal 3:-The young and potential leaders will have the vision and the capacity to achieve and sustain good governance in their respected countries.</v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AimsAndObjectivesComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('services')
      v.$store.dispatch('projects')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    services(){
      return this.$store.getters['helixGetter']('services');
    },
    projects(){
      return this.$store.getters['helixGetter']('projects');
    },
  }
}
</script>

<style scoped>

</style>
