<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Our Services</h1>
        </div>

      </v-toolbar>

      <v-container>
        <v-card flat>
          <v-card-title>
            GLOPPICON offers the following core and crosscutting services which are based on the clients’ needs and demands.
          </v-card-title>
        </v-card>
        <v-divider/>

        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Strategic planning and policy formulations  </v-card-title>
                <v-card-text>
                  GLOPPICON consultants facilitate strategic planning and policy formulation exercises. They are experienced in both contextual and policy analysis and in supporting the partners in conducting SWOT, PESTLE, Stakeholder, gender analysis; and in identifying and evaluating strategic choices and their implications; and agreeing on organizational strategic direction in the context of turbulent and dynamic global, regional and national environment. We also support the partners in collating, analyzing, evaluating and reviewing appropriate data on specific policy issues before policy adoption.

                  GLOPPICON has a pool of professional and experienced facilitators and trainers who facilitate seminars, workshops, conferences and professional training programmes.
                </v-card-text>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp;  Studies and Researches (including applied research)  </v-card-title>
                <v-card-text>
                  GLOPPICON consultants undertake all types of studies and researches. These include baseline and end line surveys, humanitarian and development assessments and feasibility studies, participatory rural appraisal (PRA/PLA) as well as market researches. GLOPPICON consultants have conducted numerous qualitative and quantitative researches and studies in the areas of its technical specialization mentioned below.
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Project designing and proposals Development </v-card-title>
                <v-card-text>
                GLOPPICON consultants conduct participatory project needs assessments where needs and problems are identified and based on which various projects and programmes are designed and planned. The consultants also develop winning and successful proposals and log-frames. They also facilitate stakeholders’ workshops for designing the project and developing the proposals and log-frames. GLOPPICON provides a full range of programme management services in all types of projects from small community based projects to humanitarian and development programmes.  GLOPPICON has reviewed projects/programmes and provided relevant recommendations on the way forward.
              </v-card-text>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Monitoring and Evaluations and Impact Assessments</v-card-title>
                <v-card-text>
                  Monitoring and evaluation are key elements of project or program management. GLOPPICON has the extensive expertise in both project/programme based and strategic monitoring and evaluations. GLOPPICON consultants develop effective project monitoring systems or plans and frameworks. They ensure that project data are effectively collected, analyzed and interpreted and that relevant decisions are made based on the well-analyzed information. The consultants build the capacity of the concerned staff on how to launch and make the systems functional and useful for their projects. The consultants also conduct third party monitoring for those who are unable to directly carry out their monitoring due to various reasons including security.

                  GLOPPICON consultants conduct mid-term and end of project/program evaluations and participatory evaluations. In addition, we conduct impact assessments of both emergency and development projects and programs. .

                </v-card-text>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Institutional capacity building and partner assessment. </v-card-title>
                <v-card-text>
                  GLOPPICON consultants are also experienced in assessing the capacity of civil society organizations (CSOs), LNGOs and public and private institutions and in strengthening institutional capacity. We also undertake partner mapping and assessment as well as capacity building. GLOPPICON has extensive expertise in strategic institutional capacity development.
                </v-card-text>
              </v-col>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Business Management and Advisory Services</v-card-title>
                <v-card-text>
                  Business enterprises are increasingly becoming important in the creation of employment and reduction of poverty. GLOPPICON consultants undertake business management and advisory services. We train the business people or potential business people on how to generate business ideas, business start ups, business plans, business marketing, stock Control and Record-keeping.
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-card-title> <v-icon small color="green">mdi-check-circle</v-icon> &nbsp; Auditing and Other Financial Services </v-card-title>
                <v-card-text>
                  GLOPPICON provides a range of financial services such as project or programme audits and development of financial systems. We have the expertise and experience financial and auditing services of the public and private as well as non-government programmes and projects.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ServicesComponent",
  data: () => ({
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      {
        title: 'Strategic planning and policy formulations',
        description:'GLOPPICON consultants facilitate strategic planning and policy formulation exercises. They are experienced in both contextual and policy analysis and in supporting the partners in conducting SWOT, PESTLE, Stakeholder, gender analysis; and in identifying and evaluating strategic choices and their implications; and agreeing on organizational strategic direction in the context of turbulent and dynamic global, regional and national environment. We also support the partners in collating, analyzing, evaluating and reviewing appropriate data on specific policy issues before policy adoption.\n' +
            '\n' +
            'GLOPPICON has a pool of professional and experienced facilitators and trainers who facilitate seminars, workshops, conferences and professional training programmes.'
      },
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
  })
}
</script>

<style scoped>

</style>
