<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>About Us</h1>
        </div>

        <template v-slot:extension>
          <v-tabs
              v-model="tabs"
              centered
          >
            <v-tab
                v-for="(n,index) in focusAreas"
                :key="index"
            >
              {{ n.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>

    <v-divider/>
    <v-container>
      <v-card flat>
        <v-card-title class="text-center">
          Who We Are?
        </v-card-title>
        <v-card-title>
          Global Peace and Prosperity Initiative (GLOPPI) is a right based international organization which strives to eliminate poverty and violent conflict and bring about lasting peace and sustainable development.
        </v-card-title>
      </v-card>
      <v-divider/>

      <v-card flat>
        <v-card-title class="text-center">
          <p class="text-center">What We Do?</p>
        </v-card-title>
        <h2 class="text-center">
          GLOPPI works in the following core areas:
        </h2>
        <v-spacer/>
        <v-spacer/>
        <v-card-text>
          <v-row>
            <v-col md="4" cols="12">
              <v-card-title> <v-icon small color="error">mdi-check-circle</v-icon> Conflict and humanitarian management: </v-card-title>
              <p>- Prevent, mitigate and transform conflict and build sustainable peace. </p>
              <p>- Respond to humanitarian crisis caused by conflict and natural disasters. </p>
            </v-col>
            <v-col md="4" cols="12">
              <v-card-title> <v-icon small color="error">mdi-check-circle</v-icon> Poverty reduction focus areas:  </v-card-title>
              <p>-Enhance livelihood and food security. </p>
              <p>-Adapt and reduce the impacts of climate change and environmental degradation in areas where people’s livelihoods depend on natural resources.  </p>
              <p>- Improve access to social services such as health, education and water.</p>
            </v-col>
            <v-col md="4" cols="12">
              <v-card-title> <v-icon small color="error">mdi-check-circle</v-icon> Human and minority rights and good governance  </v-card-title>
              <p>- Protect human and minority rights and reduce abuses through advocacy and institutional capacity building. </p>
              <p>- Mobilize and organize minority organizations in order to advocate for and achieve their rights. </p>
              <p>- Organize and mobilize civil society and citizen’s associations in order to influence and decision-makers at all levels and to achieve good governance.</p>
              <p>- Develop the capacity of young leaders to promote sustainable good governance.</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "FocusAreaComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('focusAreas')
      v.$store.dispatch('partners')
      v.$store.dispatch('projects')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    partners(){
      return this.$store.getters['helixGetter']('partners');
    },
    projects(){
      return this.$store.getters['helixGetter']('projects');
    },
    focusAreas(){
      return this.$store.getters['helixGetter']('focusAreas');
    }
  }
}
</script>

<style scoped>

</style>
