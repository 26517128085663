<template  style="background: #0E4A29">
  <v-container>
    <v-card
        class="mx-auto"
        max-width="100%"
        tile
        style="background: #0E4A29"
    >
      <v-img
          height="100%"
          src=""
      >
        <v-row
            align="end"
            class="fill-height"
        >
          <v-col cols="12" md="4">
            <v-col
                align-self="start"
                class="pa-0"
                cols="12"
            >
              <v-avatar
                  class="profile"
                  color="grey"
                  size="164"
                  tile
              >
                <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="py-0">
              <v-list-item
                  color="rgba(0, 0, 0, .4)"
                  dark
              >
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    Marcus Obrien
                  </v-list-item-title>
                  <v-list-item-subtitle>Network Engineer</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-col>
          <v-col cols="12" md="8">
            <p style="word-wrap: initial; color: aliceblue">
              Adams is a telecommunication and information engineer specialized in software integrations. He has worked as a
              software engineer for Directcore Technologies, an end-to-end Integrated IT solutions provider specializing in IT & telecoms products,
              solutions and services cutting across various market verticals in Africa, his role involved USSD, SMS, payments (mobile money and card)
              and network integrations. Adams works for EED’s subsidiary KAIOTE LTD where he is involved in the design of IoT management platforms and
              subsequent integration to LPWANs (Low Power Wide Area Networks), to provide analyzed data for day to day business processes.
              He has vast knowledge in building and deploying cloud solutions and distributed platforms that can scale to meet the customer's ever-changing needs.
              Adams holds a BSc degree in Telecommunication and Information Engineering
              from Jomo Kenyatta University of Agriculture and Technology (Kenya).
            </p>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
    <v-divider/>
    <div class="mt-10">
      <h3>Other Profiles to view:</h3>
      <v-carousel
          hide-delimiter-background
          v-model="profile">
        <v-carousel-item
            v-for="(item, i) in teamMembers"
            :key="i"
        >
          <v-sheet
              height="100%"
              tile
              background="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"
          >
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
              <v-avatar>
                <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
              </v-avatar>
              <p>{{item['first_name']}} {{item['last_name']}}</p>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ProfileComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('teamMembers')
    })
  },
  data: () => ({
    profile: true,
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
  }),
  computed: {
    teamMembers(){
      return this.$store.getters['helixGetter']('teamMembers');
    }
  }
}
</script>

<style scoped>

</style>
