<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>GLOPPI CONSULTANTS’ NETWORK (GLOPPICON)</h1>
        </div>
      </v-toolbar>
    </v-card>
    <v-container>
      <v-card>
        <v-card-title>PROFILE-Networks’ Overview/Introduction  </v-card-title>
        <v-card-text>
          GLOPPI CONSULTANTS’ NETWORK (GLOPPICON) is affiliated to Global Peace and Prosperity Initiative (GLOPPI), regional and International NGO committed and dedicated to the eradication of poverty and violent conflict. It is a non-profit-seeking network which contributes to the organizational vision of envisaging a world where peace and prosperity prevails,
          equitable and sustainable development takes place, and dignities and human rights are respected and fully enjoyed.
        </v-card-text>

        <v-divider/>
        <v-card-text>
          GLOPPICON’s mission is to provide highly quality consultancy services to its clients and partners as well as the public institutions to empower the poor, marginalized and excluded communities to earn sustainable livelihoods, reduce disaster risks and secure their basic rights. GLOPPICON has core values and principles. Transparency and accountability, integrity, confidentiality, cost-effectiveness, efficiency, professionalism, quality of services, innovativeness, creativeness and partnership are some of the values and principles which guide the GLOPPICON’s work and operations.
        </v-card-text>
        <v-divider/>
        <v-card-text>
          Based in Nairobi, GLOPPICON covers the whole east Africa and Horn of Africa sub-regions. It provides and will provide high quality services to numerous international organizations and regional authorities, as well as national governments, and community based organizations in Ethiopia, Kenya, Sudan, South Sudan, Djibouti, Tanzania, Burundi and Rwanda, Somalia and Somaliland. As the result of protracted conflict in Somalia, GLOPPICON has intensified its presence. It is fully represented in all regions of Somaliland and Somalia.
        </v-card-text>
        <v-divider/>

        <v-card-text>
          GLOPPICON has a highly experienced, educated and committed pool of international consultants. Majority hold PhD and Master Degrees in diverse fields or sectors. In the past, they worked with international NGOs and UN-agencies and assumed senior leadership positions in Horn and East Africa region. They have also worked as senior consultants before joining GLOPPICON. On average, they have over 15 years of professional experience in their respective fields. They have diverse backgrounds from human rights, WASH, food security and livelihoods to conflict transformation, peace building and good governance.
        </v-card-text>
        <v-divider/>

        <v-card-text>
          They have an excellent understanding of the regional and national humanitarian and development, socio-economic, political and security contexts. They have the advantage and the uniqueness of speaking most of the region’s major languages and hence translation is rarely required in most cases. In addition to the core professional staff, GLOPPICON has an extensive pool of national consultants who support the international consultants in undertaking certain assignments in their home countries. They also take the lead in data collection and analysis in areas where security does not permit the deployment of international consultants. In such situations, the international consultants support them from the nearest and safest possible locations to ensure highly quality standards of work. The international consultants also transfer skills to national consultants in order to enhance their capacity and effectiveness in their jobs. GLOPPICON’s consultants are respected in the fields and have had their works published in many development journals. Such publications have consolidated their expertise in their respective fields.
        </v-card-text>
        <v-divider/>

        <v-card-text>
          Furthermore, GLOPPICON has got an experienced administrative and support staff who effectively deliver and provide effective logistical, financial and administrative support to assignments whatever their scope and nature. They continuously provide support to the consultants and the teams on the ground.
        </v-card-text>
        <v-divider/>

        <v-card-text>
          GLOPPICON provides services in programme and project management, peace and governance, institutional development, training and capacity building and promoting a learning environment within organizations, researches and studies, monitoring and evaluation, impact assessment, DRR and resilience building, local economy development, Business management, facilitation of strategic plans, conflict sensitive programming, rural development, and auditing services.
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "GloppiconComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('boardMembers')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    boardMembers(){
      return this.$store.getters['helixGetter']('boardMembers');
    },
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
