<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Vacancies</h1>
        </div>
      </v-toolbar>
    </v-card>
    <v-container>
      <v-card flat>
        <v-tabs
            v-model="tab"
            centered
            icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            International & National Positions
            <v-icon>mdi-script</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Volunteer & Internship Positions
            <v-icon>mdi-script</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text>
                <v-container>
                  <p class="text-center">There are no open positions now. Please visit our website later for new vacancies</p>
                </v-container>
                <v-row>

                  <v-col v-bind:key="index" cols="12" md="6" sm="12" v-for="(item,index) in publications">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="3" sm="12">
                            <v-img :src="BASE_URL+item.attachment_url" alt=""></v-img>
                          </v-col>
                          <v-col cols="12" md="8" sm="12">
                            <div class="ma-5">
                              <p>Published on {{convertDate(item['created_at'])}}</p>
                            </div>
                            <div class="ma-5">
                              <v-divider/>
                              <h3>{{item.title}}</h3>
                            </div>
                            <div class="ma-5">
                              <v-divider/>
                              <p>{{item.description}}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-text>
                <v-container>
                  <p class="text-center">There are no open positions now. Please visit our website later for new vacancies</p>
                </v-container>
                <v-row>
                  <v-col v-bind:key="index" cols="12" md="6" sm="12" v-for="(item,index) in publications">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="3" sm="12">
                            <v-img :src="BASE_URL+item.attachment_url" alt=""></v-img>
                          </v-col>
                          <v-col cols="12" md="8" sm="12">
                            <div class="ma-5">
                              <p>Published on {{convertDate(item['created_at'])}}</p>
                            </div>
                            <div class="ma-5">
                              <v-divider/>
                              <h3>{{item.title}}</h3>
                            </div>
                            <div class="ma-5">
                              <v-divider/>
                              <p>{{item.description}}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import Mixin from "@/components/Mixin";

export default {
  name: "VacancyComponent",
  mixins: [ Mixin ],
  beforeRouteEnter(to, from, next) {
    next(v => {
      v.$store.dispatch('publications')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab: 0,
    tabs: null,
    tab: null,
    text: ''
  }),
  computed: {
    publications() {
      return this.$store.getters['helixGetter']('publications');
    },
  },
}
</script>

<style scoped>

</style>
